import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  colors: {
    ...novelaTheme.colors,
    primary: '#000',
    secondary: '#73737D',
    accent: '#fa163f',
    grey: '#73737D',
    background: '#fff',
    gradient: 'none',
    modes: {
      dark: {
        ...novelaTheme.colors.modes.dark,
        gradient: 'none',
      }
    }
  },
};
