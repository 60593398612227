import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const AttributionText = ({ children }) => {
  return <Attribution>{children}</Attribution>;
};

export default AttributionText;

const Attribution = styled.div`
  position: relative;
  top: -100px;
  color: ${p => p.theme.colors.grey};
  font-size: 14px;
  text-align: center;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    top: -50px;
  `}

  ${mediaqueries.phablet`
    top: -40px;
  `}
`;
