import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const Embed = ({ children }) => {
  return <EmbedContainer>{children}</EmbedContainer>;
};

export default Embed;

const EmbedContainer = styled.div`
  width: 100%;
  max-width: 744px;
  margin: 15px auto 50px;

  > * {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }

  ${mediaqueries.desktop`
    left: -26px;
  `};

  ${mediaqueries.tablet`
    max-width: 526px;
    padding: 20px 20px;
    left: 0;
  `};

  ${mediaqueries.phablet`
    margin: 0 auto 25px;
    padding: 25px 20px;
    width: unset;
    max-width: unset;
    float: left;
    min-width: 100%;
  `};
`;
